<template>
  <div>
    <Table
      :data="billingTableData"
      :columns="billingColumns"
      :expandable.sync="expandableRows"
      custom-class="billing-table"
      rounded
      @row-click="
        (row) => {
          hasPurchaseView && handleRowClick(row);
        }
      "
    >
      <template #cell-reference="{ rowData: { reference, missingDocumentNumber } }">
        <div v-if="reference" class="text-primary">
          <span class="p-0 text-underline-hover" @click.stop="handleDocumentRefClick(reference.id)">
            {{ reference.type ? $t(`document.exports.schema.type.shortName.${reference.type}`) : '' }}
            {{ reference.documentNumber }}
          </span>
        </div>
        <div v-else-if="missingDocumentNumber">
          <TruncatedText>
            {{
              $t('reconciliationModal.billingTable.missingDocument', {
                number: missingDocumentNumber.documentNumber,
              })
            }}
          </TruncatedText>
        </div>
        <span v-else>{{ $t('billing.billingManagement.expandableActivity.balanceOrder') }}</span>
      </template>

      <template #cell-date="{ rowData: { date, toValidate } }">
        <div :class="{ 'text-typography-primary': toValidate }">
          {{ formatDate(date) }}
        </div>
      </template>
      <template #cell-receivedProducts="{ rowData: { differencesObj, receivedProducts, currentDeliveryId } }">
        <div :class="{ [`text-${BILLING_TABLE_STATUS_COLOR[differencesObj.quantityStatus]}`]: true }">
          <div v-if="!receivedProducts">-</div>
          <Button
            v-else-if="currentDeliveryId"
            type="link"
            class="p-0 link"
            :class="{
              disabled: !hasPurchaseView,
            }"
            :disabled="!hasPurchaseView"
            @click.stop="activity = { id: currentDeliveryId, type: 'delivery' }"
          >
            {{ displayReceivedProducts(receivedProducts) }}
          </Button>
          <div v-else>
            {{ displayReceivedProducts(receivedProducts) }}
          </div>
        </div>
      </template>
      <template #cell-totalNetAmount="{ rowData: { totalNetAmount, differencesObj } }">
        <div :class="{ [`text-${BILLING_TABLE_STATUS_COLOR[differencesObj.amountStatus]}`]: true }">
          {{ formatMoney(totalNetAmount) }}
        </div>
      </template>
      <template
        #cell-imbalanceSpecification="{
          rowData: {
            imbalanceSpecification,
            differencesObj,
            missingDocumentInstance,
            toValidate,
            missingDocumentation,
          },
        }"
      >
        <div v-if="toValidate">
          {{ $t('reconciliationModal.billingTable.unValidatedEvent') }}
        </div>
        <div v-else-if="missingDocumentation">
          {{ $t('reconciliationModal.billingTable.missingDocumentation') }}
        </div>
        <div v-else-if="missingDocumentInstance">
          {{ $t('reconciliationModal.billingTable.missingDeliveryNote') }}
        </div>
        <div v-else-if="imbalanceSpecification">
          {{ imbalanceSpecification }}
        </div>
        <div v-else-if="Object.values(differencesObj).some((arr) => arr.length)">
          <div v-if="differencesObj.resultStatus == 'solvedResult'">
            {{ $t('reconciliationModal.billingTable.differenceClosed') }}
          </div>
          <div v-else-if="differencesObj.unsolvedPriceDiff.length || differencesObj.unsolvedQuantityDiff.length">
            <div v-if="differencesObj.unsolvedPriceDiff.length && differencesObj.unsolvedQuantityDiff.length">
              {{
                $t('reconciliationModal.billingTable.unresolvedDifferencesPriceAnQuantity', {
                  count: differencesObj.unsolvedPriceDiff.length + differencesObj.unsolvedQuantityDiff.length,
                  amount: formatMoney(
                    differencesObj.unsolvedPriceDiff.reduce((a, b) => a + b, 0) +
                      differencesObj.unsolvedQuantityDiff.reduce((a, b) => a + b, 0)
                  ),
                })
              }}
            </div>

            <div v-else-if="differencesObj.unsolvedPriceDiff.length">
              {{
                $tc(
                  'reconciliationModal.billingTable.unresolvedDifferencesOnlyPrice',
                  differencesObj.unsolvedPriceDiff.length,
                  {
                    count: differencesObj.unsolvedPriceDiff.length,
                    amount: formatMoney(differencesObj.unsolvedPriceDiff.reduce((a, b) => a + b, 0)),
                  }
                )
              }}
            </div>
            <div v-else-if="differencesObj.unsolvedQuantityDiff.length">
              {{
                $tc(
                  'reconciliationModal.billingTable.unresolvedDifferencesOnlyQuantity',
                  differencesObj.unsolvedQuantityDiff.length,
                  {
                    count: differencesObj.unsolvedQuantityDiff.length,
                    amount: formatMoney(differencesObj.unsolvedQuantityDiff.reduce((a, b) => a + b, 0)),
                  }
                )
              }}
            </div>
          </div>

          <div v-else>
            <div v-if="differencesObj.solvedPriceDiff.length && differencesObj.solvedQuantityDiff.length">
              {{ $t('reconciliationModal.billingTable.differenceClosed') }}
              <p class="text-decoration-line-through d-inline">
                {{
                  $t('reconciliationModal.billingTable.unresolvedDifferencesPriceAnQuantity', {
                    count: differencesObj.solvedPriceDiff.length + differencesObj.solvedQuantityDiff.length,
                    amount: formatMoney(
                      differencesObj.solvedPriceDiff.reduce((a, b) => a + b, 0) +
                        differencesObj.solvedQuantityDiff.reduce((a, b) => a + b, 0)
                    ),
                  })
                }}
              </p>
            </div>

            <div v-else-if="differencesObj.solvedPriceDiff.length">
              {{ $t('reconciliationModal.billingTable.differenceClosed') }}
              <p class="text-decoration-line-through d-inline">
                {{
                  $tc(
                    'reconciliationModal.billingTable.unresolvedDifferencesOnlyPrice',
                    differencesObj.unsolvedPriceDiff.length,
                    {
                      count: differencesObj.solvedPriceDiff.length,
                      amount: formatMoney(differencesObj.solvedPriceDiff.reduce((a, b) => a + b, 0)),
                    }
                  )
                }}
              </p>
            </div>
            <div v-else-if="differencesObj.unsolvedQuantityDiff.length">
              {{ $t('reconciliationModal.billingTable.differenceClosed') }}
              <p class="text-decoration-line-through d-inline">
                {{
                  $tc(
                    'reconciliationModal.billingTable.unresolvedDifferencesOnlyQuantity',
                    differencesObj.solvedQuantityDiff.length,
                    {
                      count: differencesObj.solvedQuantityDiff.length,
                      amount: formatMoney(differencesObj.solvedQuantityDiff.reduce((a, b) => a + b, 0)),
                    }
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template #cell-actions="{ rowData: { missingEventId }, rowIndex }">
        <el-dropdown
          v-if="isAdmin && missingEventId"
          class="d-flex justify-content-center"
          trigger="click"
          placement="bottom"
          @command="handleAction($event, missingEventId)"
          @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
        >
          <Button
            :id="`actions-row-${rowIndex}`"
            type="text"
            class="p-0 action-button hover-btn text-typography-primary"
            :class="{ active: activeActions === rowIndex }"
            @click.stop
          >
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item
              v-if="isAdmin && missingEventId"
              :disabled="!isAdmin"
              :command="ACTION.RESOLVE_MISSING_EVENT"
            >
              {{ $t('billing.billingManagement.expandableActivity.actions.resolveMissingEvent') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #expandable-content="{ rowData: { itemsTableData }, rowIndex }">
        <ItemsTable :key="rowIndex" :order="itemsTableData" />
      </template>
    </Table>

    <EventMapModal v-if="activity" :activity="activity" @close="handleActivityClose" />

    <DocumentModal
      v-if="displayedDocumentId"
      visible
      :document-id="displayedDocumentId"
      @close="displayedDocumentId = null"
    />

    <OrderDifferenceMatchModal
      v-if="orderToValidate"
      :default-month="orderToValidate.month"
      :supplier="orderToValidate.supplier"
      :business="business"
      :order="orderToValidate.order"
      @refetch="$emit('refetch')"
      @close="orderToValidate = null"
    />
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { isEmpty, isNil } from 'ramda';

import { KebabIcon } from '@/assets/icons';
import { options } from '@/locale/dateConfig';
import { Table, Button, TruncatedText } from '@/modules/core';
import { useUser } from '@/modules/auth';
import { DocumentModal } from '@/modules/documentModal';
import { usePatchMissingEvent } from '@/modules/reconciliation';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useGlobalPermissions } from '@/modules/permissions';

import { BILLING_TABLE_STATUSES, BILLING_TABLE_STATUS_COLOR } from '../..';
import ItemsTable from './ItemsTable';

const BILLING_TABLE_HEADERS = {
  REFERENCE: 'reference',
  DATE: 'date',
  ORDERED_PRODUCTS_NUMBER: 'orderedProductsNumber',
  RECEIVED_PRODUCTS: 'receivedProducts',
  TOTAL_NET_AMOUNT: 'totalNetAmount',
  IMBALANCE_SPECIFICATIONS: 'imbalanceSpecification',
  ACTIONS: 'actions',
};

const ISSUES = {
  IMBALANCE_QUANTITY: 'imbalanceQuantity',
  IMBALANCE_PRICE: 'imbalancePrice',
  IMBALANCE_QUANTITY_AND_PRICE: 'imbalanceQuantityPrice',
  NO_ISSUES: 'noIssues',
};

const ACTION = {
  RESOLVE_MISSING_EVENT: 'resolveMissingEvent',
};

export default {
  name: 'BillingTable',
  components: {
    OrderDifferenceMatchModal: () => import('@/modules/activity/components/orderDifferenceMatchModal'),
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
    Table,
    DocumentModal,
    ItemsTable,
    KebabIcon,
    Button,
    TruncatedText,
  },
  props: {
    billing: { type: Object, required: true },
    deliveryTaskIds: { type: Array, default: () => [] },
    missingEvent: { type: Array, default: () => [] },
    orderDifferences: { type: Array, default: () => [] },
    supplier: { type: Object, default: () => {} },
    business: { type: Object, required: true },
  },
  setup(_, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const { formatToCurrency } = useCurrency();
    const { hasPurchaseView } = useGlobalPermissions();
    const { isAdmin } = useUser();

    const {
      patchMissingEvent,
      onDone: patchMissingEventOnDone,
      onError: patchMissingEventOnError,
    } = usePatchMissingEvent();

    patchMissingEventOnDone(() => {
      $message.success($i18n.t('billing.billingManagement.messages.missingEventResolvedSuccess'));
      emit('refetch');
    });
    patchMissingEventOnError(() =>
      $message.error($i18n.t('billing.billingManagement.messages.missingEventResolvedError'))
    );

    return {
      hasPurchaseView,
      isAdmin,
      ISSUES,
      displayedDocumentId: ref(null),
      activity: ref(null),
      expandableRows: ref({}),
      BILLING_TABLE_STATUSES,
      BILLING_TABLE_STATUS_COLOR,
      orderToValidate: ref(null),
      activeActions: ref(null),
      ACTION,
      patchMissingEvent,
      formatToCurrency,
    };
  },
  computed: {
    billingColumns() {
      const {
        REFERENCE,
        DATE,
        ORDERED_PRODUCTS_NUMBER,
        RECEIVED_PRODUCTS,
        TOTAL_NET_AMOUNT,
        IMBALANCE_SPECIFICATIONS,
        ACTIONS,
      } = BILLING_TABLE_HEADERS;
      return [
        {
          header: this.$t('reconciliationModal.billingTable.reference'),
          key: [REFERENCE],
          width: '12rem',
        },
        {
          header: this.$t('reconciliationModal.billingTable.date'),
          key: [DATE],
          width: '8rem',
        },
        {
          header: this.$t('reconciliationModal.billingTable.orderedProducts'),
          key: [ORDERED_PRODUCTS_NUMBER],
          width: '10rem',
        },
        {
          header: this.$t('reconciliationModal.billingTable.receivedProducts'),
          key: [RECEIVED_PRODUCTS],
          width: '10.5rem',
        },
        {
          header: this.$t('reconciliationModal.billingTable.netAmount'),
          key: [TOTAL_NET_AMOUNT],
          width: '12rem',
        },
        {
          header: this.$t('reconciliationModal.billingTable.imbalanceDetails'),
          key: [IMBALANCE_SPECIFICATIONS],
        },
        {
          header: '',
          key: [ACTIONS],
          width: '2.5rem',
        },
      ];
    },

    billingTableData() {
      const {
        REFERENCE,
        DATE,
        ORDERED_PRODUCTS_NUMBER,
        RECEIVED_PRODUCTS,
        TOTAL_NET_AMOUNT,
        IMBALANCE_SPECIFICATIONS,
      } = BILLING_TABLE_HEADERS;

      const deliveryOrderArr =
        this.billing.orderLinks
          // HACK show only first order link info in case there is several order links linked to the same order
          ?.reduce((links, { order }) => {
            const existingLinkIndex = links.findIndex(({ id }) => id === order.id);
            if (existingLinkIndex === -1) links.push(order);
            return links;
          }, [])
          .map((order) => ({
            order,
            delivery: this.billing.deliveryRefs.find(({ delivery }) => {
              return delivery.orderIds.includes(order.id);
            })?.delivery,
          }))
          .sort((a, b) => new Date(a.order.date) - new Date(b.order.date)) || [];

      const tableData = deliveryOrderArr.map(({ order, delivery }) => ({
        [REFERENCE]: order.source?.document ?? null,
        [DATE]: order.date ?? null,
        [ORDERED_PRODUCTS_NUMBER]: order.itemsCount?.ordered ? order.itemsCount.ordered : '-',
        [RECEIVED_PRODUCTS]: delivery?.products?.reduce(
          (prev, curr) => {
            curr.quantity >= 0 ? (prev.received = prev.received + 1) : (prev.returned = prev.returned + 1);
            return prev;
          },
          { received: 0, returned: 0 }
        ),
        [TOTAL_NET_AMOUNT]: order.netAmount,
        [IMBALANCE_SPECIFICATIONS]: this.getTextForImbalanceSpecification(order),
        differencesObj: this.getDifferencesSpecification(order.differences),
        disabledTextColor: !order.source?.document,
        orderId: order.id,
        currentDeliveryId: delivery?.id,
        supplierId: this.billing.supplierId,
        billingId: this.billing.id,
        itemsTableData: { ...order },
        expandable: this.checkIfOrderHasUnsolvedDifferences(order.differences),
        expandableCustomClass: 'p-0 bg-light',
        missingDocumentation: order?.source?.document?.id && this.deliveryTaskIds.includes(order.source.document.id),
      }));

      if (!isEmpty(this.missingEvent)) {
        tableData.push(
          ...this.missingEvent.map((event) => ({
            [REFERENCE]: null,
            [DATE]: null,
            [ORDERED_PRODUCTS_NUMBER]: '-',
            [RECEIVED_PRODUCTS]: null,
            missingDocumentNumber: { documentNumber: event.reference },
            differencesObj: this.getDifferencesSpecification([]),
            disabledTextColor: true,
            missingDocumentInstance: true,
            missingEventId: event.id,
          }))
        );
      }

      if (this.orderDifferences.length) {
        tableData.push(
          ...[...this.orderDifferences]
            .sort((a, b) => a.date - b.date)
            .map((order) => ({
              [REFERENCE]:
                {
                  id: order.eventReferences[0]?.documentId,
                  type: order.eventReferences[0]?.document?.type,
                  documentNumber: order.eventReferences[0]?.reference,
                } ?? null,
              [DATE]: order.date ?? null,
              [ORDERED_PRODUCTS_NUMBER]: '-',
              [RECEIVED_PRODUCTS]: order.products?.reduce(
                (prev, curr) => {
                  curr.quantity >= 0 ? (prev.received = prev.received + 1) : (prev.returned = prev.returned + 1);
                  return prev;
                },
                { received: 0, returned: 0 }
              ),
              [TOTAL_NET_AMOUNT]: order?.netAmount,
              differencesObj: this.getDifferencesSpecification([]),
              disabledTextColor: !order.eventReferences.length,
              orderId: order.id,
              currentDeliveryId: null,
              supplierId: this.billing.supplierId,
              billingId: this.billing.id,
              toValidate: true,
              order,
            }))
        );
      }

      return tableData;
    },
  },
  methods: {
    displayReceivedProducts(receivedProducts) {
      if (!receivedProducts.returned) {
        return receivedProducts.received;
      } else {
        return `${receivedProducts.received} (${this.$tc(
          'reconciliationModal.billingTable.receivedProductsText',
          receivedProducts.returned
        )})`;
      }
    },
    checkIfOrderHasUnsolvedDifferences(differences) {
      const diffs = this.getDifferencesSpecification(differences);

      return diffs.unsolvedPriceDiff.length || diffs.unsolvedQuantityDiff.length;
    },
    getDifferencesSpecification(differences) {
      const differencesObj = differences
        ? differences.reduce(
            (prev, curr) => {
              if (curr.netPrice) {
                curr.netPrice.solved
                  ? prev.solvedPriceDiff.push(curr.amount)
                  : prev.unsolvedPriceDiff.push(curr.amount);
              }
              if (curr.quantity) {
                curr.quantity.solved
                  ? prev.solvedQuantityDiff.push(curr.quantityAmount)
                  : prev.unsolvedQuantityDiff.push(curr.quantityAmount);
              }
              if (curr.discount) {
                curr.discount.solved
                  ? prev.solvedPriceDiff.push(curr.pricingAmount)
                  : prev.unsolvedPriceDiff.push(curr.pricingAmount);
              }
              return prev;
            },
            { solvedPriceDiff: [], solvedQuantityDiff: [], unsolvedPriceDiff: [], unsolvedQuantityDiff: [] }
          )
        : { solvedPriceDiff: [], solvedQuantityDiff: [], unsolvedPriceDiff: [], unsolvedQuantityDiff: [] };

      let resultStatus = this.BILLING_TABLE_STATUSES.NETURAL_RESULT;

      if (differencesObj.unsolvedPriceDiff.length || differencesObj.unsolvedQuantityDiff.length) {
        resultStatus = this.BILLING_TABLE_STATUSES.UNSOLVED_RESULT;
      } else if (differencesObj.solvedPriceDiff.length || differencesObj.solvedQuantityDiff.length) {
        resultStatus = this.BILLING_TABLE_STATUSES.SOLVED_RESULT;
      }

      differencesObj.resultStatus = resultStatus;

      let amountStatus = this.BILLING_TABLE_STATUSES.NETURAL_AMOUNT;

      if (differencesObj.unsolvedPriceDiff.length) {
        amountStatus = this.BILLING_TABLE_STATUSES.UNSOLVED_AMOUNT;
      } else if (differencesObj.solvedPriceDiff.length) {
        amountStatus = this.BILLING_TABLE_STATUSES.SOLVED_AMOUNT;
      }

      differencesObj.amountStatus = amountStatus;

      let quantityStatus = this.BILLING_TABLE_STATUSES.NETURAL_QUANTITY;

      if (differencesObj.unsolvedQuantityDiff.length) {
        quantityStatus = this.BILLING_TABLE_STATUSES.UNSOLVED_QUANTITY;
      } else if (differencesObj.solvedQuantityDiff.length) {
        quantityStatus = this.BILLING_TABLE_STATUSES.SOLVED_QUANTITY;
      }

      differencesObj.quantityStatus = quantityStatus;

      return differencesObj;
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' }) : '-';
    },
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
    handleDocumentRefClick(documentId) {
      this.displayedDocumentId = documentId;
    },
    getTextForImbalanceSpecification(order) {
      if (isNil(order?.netAmount)) return this.$t('reconciliationModal.billingTable.missingOrderPrice');
      else if (!order?.itemsCount)
        return this.$t('reconciliationModal.billingTable.missingDocumentationRestaurantSide', {
          businessName: this.business.name,
        });
      else return '';
    },
    handleActivityClose() {
      this.activity = null;
      this.$emit('refetch');
    },
    handleRowClick(index) {
      if (this.billingTableData[index].toValidate) {
        if (!this.isAdmin) return;
        this.orderToValidate = {
          month: DateTime.fromISO(this.billingTableData[index].date).toFormat('yyyy-LL'),
          supplier: this.supplier,
          order: this.billingTableData[index].order,
        };
      } else {
        this.billingTableData[index].orderId
          ? (this.activity = { id: this.billingTableData[index].orderId, type: 'order' })
          : null;
      }
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    handleAction(command, missingEventId) {
      switch (command) {
        case ACTION.RESOLVE_MISSING_EVENT:
          this.patchMissingEvent({ id: missingEventId, data: { resolved: true } });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.disabled {
  cursor: default !important;
  text-decoration: none !important;
  color: var(--typography-primary) !important;
}

.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

::v-deep .billing-table tr.to-validate:hover {
  cursor: pointer !important;
  background-color: #f8fafb !important;
}

::v-deep .link {
  text-decoration: underline;
  color: $typography-primary;
  &:hover {
    color: $primary;
    cursor: pointer !important;
  }
}

::v-deep .billing-table {
  tr {
    .action-button {
      visibility: hidden;
    }

    &:hover .action-button {
      visibility: visible;
    }

    .hover-btn {
      &:hover {
        background: $secondary;
      }
      &.active {
        visibility: visible;
      }
    }
  }
}
</style>
